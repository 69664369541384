import React from "react"
import Layout from "../components/layouts/layout_blank"
import SEO from "../components/seo/seo"
import SectionOne from "./section/s_sales-page/discount/eb/s_one"
import SectionTwo from "./section/s_sales-page/discount/eb/s_two"
import SectionTheree from "./section/s_sales-page/discount/eb/s_theree"
import SectionFour from "./section/s_sales-page/discount/eb/s_four"
import SectionFive from "./section/s_sales-page/discount/eb/s_five"
import SectionSix from "./section/s_sales-page/discount/eb/s_six"
import SectionSeven from "./section/s_sales-page/discount/eb/s_seven"
import SectionFaq from "./section/s_sales-page/discount/eb/s_faq"
import SectionCountdown from "./App"

const IndexPage = () => (
  <Layout>
    <React.Fragment>
      <SEO title="Sales Page" />
      <SectionCountdown />
      <SectionOne />
      <SectionTwo />
      <SectionTheree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionFaq />
    </React.Fragment>
  </Layout>
)

export default IndexPage
